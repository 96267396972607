import React, { useState, useRef, useEffect } from "react";
import * as emailjs from "emailjs-com";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { personal } from '../../content_option';
import { Container, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ContactUs = () => {
  useEffect(() => emailjs.init(personal.PUBLIC_KEY), []);

  const { t, i18n } = useTranslation();
  const form = useRef();

  const [formData, setFormdata] = useState({
    email: "",
    name: "",
    message: "",
    loading: false,
    show: false,
    alertmessage: "",
    variant: "",
  });

  const handleChange = (e) => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormdata({
      loading: true
    });
    try {      
      await emailjs.send(personal.SERVICE_ID, personal.TEMPLATE_ID, {
        from_email: formData.email,
        from_name: formData.name,
        to_name: personal.fullname,
        message: formData.message,
        lang: i18n.language
      });
      setFormdata({
        loading: false,
        alertmessage: t('pages.contact.sentsuccess'),
        variant: "success",
        show: true,
      });
    } catch (error) {
      setFormdata({
        loading: false,
        alertmessage: t('pages.contact.senterror'),
        variant: "danger",
        show: true,
      });
      document.getElementsByClassName("co_alert")[0].scrollIntoView();
    } finally {
    }
  };

  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{personal.fullname} | Contact</title>
          <meta name="description" content="djumo website" />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">{t('pages.home.contactme')}</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="12">
            <Alert
              //show={formData.show}
              variant={formData.variant}
              className={`rounded-0 co_alert ${formData.show ? "d-block" : "d-none"
                }`}
              onClose={() => setFormdata({ show: false })}
              dismissible
            >
              <p className="my-0">{formData.alertmessage}</p>
            </Alert>
          </Col>
          <Col lg="5" className="mb-5">
            <h3 className="color_sec py-4">{t('pages.contact.getintouch')}</h3>
            <address>
              <strong>{t('pages.contact.email')}:</strong>{" "}
              <a href={`mailto:${personal.email}`}>
                {personal.email}
              </a>
              <br />
              <br />
              {personal.hasOwnProperty('phone') ? (
                <p>
                  <strong>{t('pages.contact.phone')}:</strong> {personal.phone}
                </p>
              ) : (
                ""
              )}
            </address>
            <p>{t('pages.contact.description')}</p>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <form ref={form} onSubmit={handleSubmit} className="contact__form w-100">
              <Row>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder={t('pages.contact.name')}
                    value={formData.name || ""}
                    type="text"
                    required
                    onChange={handleChange}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    id="email"
                    name="email"
                    placeholder={t('pages.contact.email')}
                    type="email"
                    value={formData.email || ""}
                    required
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <textarea
                className="form-control rounded-0"
                id="message"
                name="message"
                placeholder={t('pages.contact.message')}
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <br />
              <Row>
                <Col lg="12" className="form-group">
                  <button className="btn ac_btn" type="submit">
                    {formData.loading ? t('pages.contact.sending') : t('pages.contact.send')}
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
      <div className={formData.loading ? "loading-bar" : "d-none"}></div>
    </HelmetProvider>
  );
};
