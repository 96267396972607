import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import withRouter from "../hooks/withRouter";
import AppRoutes from "./routes";
import Headermain from "../header";
import AnimatedCursor from "../hooks/AnimatedCursor";
import "./App.css";

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { firebaseConfig } from "./../firebase-key.js"

import PortfolioDialog from '../components/portfoliodialog'

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export default function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const portfolioModalRef = useRef(null);

  function loadModal(data) {
    const modal = portfolioModalRef.current;

    if (modal) {
      modal.setData(data);
      modal.show();
      setModalOpen(true);
    }
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {<div className="cursor__dot">
        {<AnimatedCursor
          innerSize={15}
          outerSize={15}
          color="255, 255 ,255"
          outerAlpha={0.4}
          innerScale={0.7}
          outerScale={5}
        />}
      </div>}
      <ScrollToTop>
        <PortfolioDialog className="fixed-top" ref={portfolioModalRef}  onClose={() => setModalOpen(false)} />
        <Headermain />
        <AppRoutes modalRequest={loadModal} modalOpen={modalOpen} />
      </ScrollToTop>
    </Router>
  );
}
