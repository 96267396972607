import React, { useState } from "react";
import "./style.css";
import { VscGrabber, VscClose } from "react-icons/vsc";
import { Link } from "react-scroll";
import Themetoggle from "../components/themetoggle";
import Langselector from "../components/langselector";
import { personal } from '../content_option'
import { useTranslation } from "react-i18next";

import {
  FaGithub,
  FaLinkedin
} from "react-icons/fa";

const Headermain = () => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  return (
    <>
      <header className="fixed-top site__header">
        <div className="site__header_row">
          <Link className="navbar-brand nav_ac" to="home-section" spy={true} smooth={true} offset={-100} duration={500}>
            {personal.logotext}
          </Link>

          <div className="link_list">
            <Link className="link_list_item" to='home-section' spy={true} smooth={true} offset={-100} duration={500}>{t('pages.header.home')}</Link>
            <Link className="link_list_item" to='portfolio-section' spy={true} smooth={true} offset={-100} duration={500}>{t('pages.header.portfolio')}</Link>
            <Link className="link_list_item" to="about-section" spy={true} smooth={true} offset={-100} duration={500}>{t('pages.header.about')}</Link>
            <Link className="link_list_item" to="contact-section" spy={true} smooth={true} offset={-100} duration={500}>{t('pages.header.contact')}</Link>
          </div>

          <div className="d-flex align-items-center">
            <Themetoggle />
            <Langselector />
            <button className="menu__button nav_ac navigation_button" onClick={handleToggle}>
              {!isActive ? <VscClose /> : <VscGrabber />}
            </button>
          </div>
        </div>

        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="home-section" className="my-3" spy={true} smooth={true} offset={-100} duration={500}>{t('pages.header.home')}</Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="portfolio-section" className="my-3" spy={true} smooth={true} offset={-100} duration={500}> {t('pages.header.portfolio')}</Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="about-section" className="my-3" spy={true} smooth={true} offset={-100} duration={500}>{t('pages.header.about')}</Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="contact-section" className="my-3" spy={true} smooth={true} offset={-100} duration={500}> {t('pages.header.contact')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
              {personal.hasOwnProperty('linkedin') && (
                <li>
                  <a href={personal.linkedin} target="_blank">
                    <FaLinkedin />
                  </a>
                </li>
              )}
              {personal.hasOwnProperty('github') && (
                <li>
                  <a href={personal.github} target="_blank">
                    <FaGithub />
                  </a>
                </li>
              )}
            </div>
            <p className="copyright m-0">copyright {personal.logotext}</p>
          </div>
        </div>
      </header>
      <div className="br-top"></div>
      <div className="br-bottom"></div>
      <div className="br-left"></div>
      <div className="br-right"></div>

    </>
  );
};

export default Headermain;
