const personal = {
    logotext: "djumo",
    fullname: "Cumhur Sayan",
    email: "dev@djumo.com",
    PUBLIC_KEY: "34eKuDUQCBQnXXueE",
    SERVICE_ID: "service_nhqw01i",
    TEMPLATE_ID: "template_vp7wiqw",
    github: "https://github.com/cumbaba",
    linkedin: "https://linkedin.com/in/cumhursayan",
    img_url: "assets/images/djumo-simage.jpg",
    cv_file: "/assets/cv/CV_Cumhur_Sayan_English.pdf"
};

const skills = [
    {
        name: "Object Oriented Programming",
        value: 90,
    },
    {
        name: "Clean Code and Clean Arthitecture",
        value: 90,
    },
    {
        name: "Embedded Software Development",
        value: 85,
    },
    {
        name: "GUI/HMI Applications",
        value: 100,
    },
    {
        name: "Web Applications",
        value: 60,
    },
    {
        name: "Scripting Languages and Automation",
        value: 55,
    }
];

export {
    personal,
    skills
};