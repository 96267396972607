import React from "react";
import withRouter from "../hooks/withRouter"
import { Home } from "../pages/home";
import { Portfolio } from "../pages/portfolio";
import { ContactUs } from "../pages/contact";
import { About } from "../pages/about";
import { Socialicons } from "../components/socialicons";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnimatedRoutes = withRouter(({ location, onModalRequest }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <div>

        <section id="home-section"><Home /></section>
        <section id="portfolio-section"><Portfolio onModalRequest={onModalRequest} /></section>
        <section id="about-section"><About /></section>
        <section id="contact-section"><ContactUs /></section>
      </div>
    </CSSTransition>
  </TransitionGroup>
));

const AppRoutes = ({ modalRequest, modalOpen }) => {
  return (
    <div >
      <div className="s_c">
        <AnimatedRoutes onModalRequest={modalRequest} style={{ pointerEvents: modalOpen ? 'none' : 'auto' }} />
        <Socialicons />
      </div>
    </div>
  );
}

export default AppRoutes