import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { personal, skills } from '../../content_option';
import { useTranslation } from "react-i18next";

// Function to replace each substring occurrence in the text with its bold version
function PartiallyBoldText({ text, boldWords }) {
  const boldText = (text) => {
    let boldText = text;
    boldWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      boldText = boldText.replace(regex, (match) => `<strong>${match}</strong>`);
    });
    return boldText;
  };

  return (
    <p dangerouslySetInnerHTML={{ __html: boldText(text) }} />
  );
};

export const About = () => {
  const { t } = useTranslation();
  const boldWordsAboutMe = ["Besiktas", "St. Pauli", "Istanbul", "Hamburg", "Balikesir", "mechatronics", "mekatronik", "Mechatronik"];
  const boldWordsWorkDescription = ["medical", "Medizin", "Medikal", "SCRUM", "MVP", "best practices", , "best practice", "modern standards",
    "modernen Standards", "modern standartlar", "worldwide", "weltweit", "global", "Clean Code"];

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About | {personal.fullname}</title>
          <meta name="description" content="djumo website" />
        </Helmet>
        <Row className="mb-5">
          <Col lg="12">
            <div className="about-row">
              <h1 className="display-4" >{t('pages.about.aboutme')}</h1>
              <Link to={personal.cv_file} className="ac_btn btn download-button" target="_blank" download>{t('dataabout.download')}</Link>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp mb-2">
          <Col lg="3">
            <h3 className="color_sec">{t('dataabout.title')}</h3>
          </Col>
          <Col lg="9" className="d-flex align-items-center">
            <div>
              <PartiallyBoldText text={t('dataabout.aboutme')} boldWords={boldWordsAboutMe} />
            </div>
          </Col>
        </Row>

        <Row className="sec_sp mb-2">
          <Col lang="5">
            <h3 className="color_sec py-4">{t('pages.about.worktimeline')}</h3>
          </Col>
          <Col lg="9">
            {t('worktimeline', { returnObjects: true }).map((data, i) => {
              return (
                <div className="service_ py-4" key={i}>
                  <div className="service__title">
                    <tr className="tr_worktimeline">
                      <h5>{data.jobtitle}</h5>
                      <div className="tr_worktimeline_title">
                        <td className="tr_worktimeline_title_italic">
                          <a href={data.website} target="_blank"> {data.where}</a>
                        </td>

                        <td className="tr_worktimeline_title_bold">{data.date}</td>
                      </div>
                    </tr>
                  </div>
                  <PartiallyBoldText className="worktimeline_desc" text={data.description} boldWords={boldWordsWorkDescription} />
                </div>
              );
            })}
          </Col>
        </Row>

        <Row className="sec_sp mb-2">
          <Col lang="5">
            <h3 className="color_sec py-4">{t('pages.about.education')}</h3>
          </Col>
          <Col lg="9">
            {t('education', { returnObjects: true }).map((data, i) => {
              return (
                <div className="service_ py-4" key={i}>
                  <div className="service__title">
                    <tr className="tr_worktimeline">
                      <h5>{data.degree}</h5>
                      <div className="tr_worktimeline_title">
                        <td className="tr_worktimeline_title_italic">
                          <a href={data.website} target="_blank"> {data.where}</a>
                        </td>

                        <td className="tr_worktimeline_title_bold">{data.date}</td>
                      </div>
                    </tr>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>

        <Row className="sec_sp mb-2">
          <Col lg="3">
            <h3 className="color_sec py-5">{t('pages.about.skills')}</h3>
          </Col>
          <Col lg="9" className="py-5">
            {skills.map((data, i) => {
              return (
                <div key={i}>
                  <h3 className="progress-title">{data.name}</h3>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${data.value}%`,
                      }}
                    >
                      <div className="progress-value">{data.value}%</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lang="5">
            <h3 className="color_sec py-4">{t('pages.about.services')}</h3>
          </Col>
          <Col lg="9">
            {t('services', { returnObjects: true }).map((data, i) => {
              return (
                <div className="service_ py-4" key={i}>
                  <h5 className="service__title">{data.title}</h5>
                  <p className="service_desc">{data.description}</p>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
