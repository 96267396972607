import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { personal } from '../../content_option';
import { useTranslation } from "react-i18next";

export const Portfolio = ({ onModalRequest }) => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <Container className="About-header" >
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {personal.fullname} </title>{" "}
          <meta name="description" content="djumo website" />
        </Helmet>
        <Row className="">
          <Col lg="8">
            <h1 className="display-4 mb-4"> {t('pages.portfolio.portfolio')} </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>

        <div className="mb-3 po_items_ho">
          {t('dataportfolio', { returnObjects: true }).map((data, i) => {
            return (
              <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <button className="content" onClick={e => onModalRequest(data)}>
                  <p>{data.summary}</p>
                  {"<< "}{t('pages.portfolio.viewproject')}{" >>"}
                </button>
              </div>
            );
          })}
        </div>
      </Container>
    </HelmetProvider>
  );
};
