import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const LangSelector = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const options = t('languages', { returnObjects: true });

  const lang_key = (i18n.language || window.localStorage.i18nextLng || '  ').substring(0, 2);
  const selectedLang = options.find((op) => {
    return op.key === lang_key;
  });

  const [selectedOption, setSelectedOption] = useState(selectedLang ? selectedLang : options[0]);

  const close = () => {
    const modal = document.getElementById("lang-list");
    modal.style.animation = 'disappear 200ms ease-out';

    setTimeout(function () {
      modal.style.display = 'none';
      modal.style.animation = 'appear 200ms ease-in';
    }, 200);
  };

  const toggling = () => {
    if (isOpen) {
      close();
    } else {
      const modal = document.getElementById("lang-list");
      modal.style.display = "block";
    }

    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option) => {
    close();
    setSelectedOption(option);
    i18n.changeLanguage(option.key);
  };

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        close();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div
      ref={wrapperRef}
      className="nav_ac lang_dropdown"
      onClick={toggling}>
      <div>
        {selectedOption !== null && (
          <img
            src={`https://hatscripts.github.io/circle-flags/flags/${selectedOption.key === 'en' ? 'gb' : selectedOption.key}.svg`}
            alt={selectedOption.label} />
        )}
      </div>

      {options !== null && (
        <ul id="lang-list">
          {options.map(option => (
            <li
              onClick={() => onOptionClicked(option)}
              key={option.key}>
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${option.key === 'en' ? 'gb' : option.key}.svg`}
                alt={option.label}
                title={option.label} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LangSelector;
