import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import "./style.css";

const PortfolioDialog = forwardRef(({ onClose }, ref) => {
  const [data, setData] = useState({});
  const [scrollToTop, setScrollToTop] = useState(false);

  const show = () => {
    const modal = overlayRef.current;
    modal.style.display = "flex";
    setScrollToTop(true);

  };

  const hide = () => {
    const modal = overlayRef.current;

    modal.style.animation = 'disappear 200ms ease-out';

    setTimeout(function () {
      modal.style.display = 'none';
      modal.style.animation = 'appear 200ms ease-in';
      onClose();
    }, 200);
  };

  useImperativeHandle(ref, () => ({
    setData,
    show,
    hide
  }));

  const overlayRef = useRef(null);
  const modalRef = useRef(null);

  function isInsideModal(event) {
    return modalRef.current &&
      overlayRef.current &&
      overlayRef.current.contains(event.target) &&
      modalRef.current.contains(event.target);
  }

  function isVisible() {
    return overlayRef.current && overlayRef.current.style.display === "flex";
  }

  function isScrollable(element) {
    return element.scrollHeight > element.clientHeight
  };

  function hasScrollableSpace(up, element) {
    return up ? element.scrollTop > 0 : element.scrollHeight - element.clientHeight > element.scrollTop;
  };

  function isWheelScrollingTop(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isVisible() && !isInsideModal(event)) {
        hide();
      }
    };
    const handleTouch = (event) => {
      if (isVisible() &&
        !(isInsideModal(event) && isScrollable(modalRef.current))) {
        event.preventDefault();
      }
    };
    const handleWheel = (event) => {
      if (isVisible() &&
        !(isInsideModal(event) && isScrollable(modalRef.current) && hasScrollableSpace(isWheelScrollingTop(event), modalRef.current))) {
        event.preventDefault();
      }
    };
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        hide();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.body.addEventListener('wheel', handleWheel, { passive: false });
    document.body.addEventListener('touchmove', handleTouch, { passive: false });
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.removeEventListener('wheel', handleWheel, { passive: false });
      document.body.removeEventListener('touchmove', handleTouch, { passive: false });
      document.removeEventListener('keydown', handleEsc);
    };
  });


  useEffect(() => {
    if (scrollToTop) {
      modalRef.current.scrollTop = 0;
      setScrollToTop(false);
    }
  }, [scrollToTop]);

  return (
    <div ref={overlayRef}
      className="overlay" >

      {data != {}} ?
      (<div className="modal-bg" />

      <div ref={modalRef}
        className="modal">

        <img src={data.img} />
        <div className="text-content">
          <h1> {data.header} </h1>
          <h4> {data.description} </h4>

          <h3>Stack</h3>
          <ul>
            {data.languages !== undefined ? data.languages.map((tech, i) => (
              <li>
                <a href={tech.url} target="_blank">
                  <img
                    key={i}
                    className="tech-icon"
                    src={tech.image}
                    title={tech.title}
                  />
                </a>
              </li>
            )) : []}
          </ul>
          <ul>
            {data.stack !== undefined ? data.stack.map((tech, i) => (
              <li>
                <a href={tech.url} target="_blank">
                  <img
                    key={i}
                    className="tech-icon"
                    src={tech.image}
                    title={tech.title}
                  />
                </a>
              </li>
            )) : []}
          </ul>
        </div>
        <button className="closeButton" onClick={hide}>&#x2715;</button>
      </div>)
      : { }
    </div >
  );
});

export default PortfolioDialog;
